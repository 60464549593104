<template>
	<div class="search">
		<div class="container">
			<div class="category">
				<button :class="{ 'active': category === 1 }" @click="changeCategory(1)"><b-icon icon="box-seam" class="icon"></b-icon> ยางรถยนต์</button>
				<button :class="{ 'active': category === 2 }" @click="changeCategory(2)"><b-icon icon="shop" class="icon"></b-icon> ร้านยาง</button>
				<button :class="{ 'active': category === 3 }" @click="changeCategory(3)"><b-icon icon="journal-text" class="icon"></b-icon> บทความ</button>
			</div>

			<h1 class="mt-3 mb-3">
				<label>คำที่ใช้ค้นหา</label>
				<span><b-icon icon="pencil" class="icon"></b-icon> {{ title }}</span>
			</h1>

			<template v-if="category === 1 || category === 2">
				<div class="content-container mt-3">
					<div class="filter">
						<div class="loading" v-if="!isContentReady">
							<loader-content :isDisplay="true"></loader-content>
						</div>
						<template v-if="isContentReady">
							<div class="content-mobile-filter-modal" :class="{ 'active': isShowMobileFilter }" @click="toggleFilterMobile()">
								<div class="box" @click.stop>
									<perfect-scrollbar>
										<div class="filter-title">
											<b-icon icon="sliders" class="icon"></b-icon> กรองผลการค้นหา <b-icon icon="x" @click="toggleFilterMobile()" class="close-icon"></b-icon>
										</div>
										<filter-search
											v-if="filterOptions && tags"
											ref="filterSearch"
											:filterOptions="filterOptions"
											:tagSelections="tags"
											@filterResult="changeFilter($event)">
										</filter-search>
									</perfect-scrollbar>
									<div class="operation-panel">
										<div class="wrapper">
											<button class="btn btn-reset btn-outline-secondary" @click="resetFilter()">รีเซ็ต</button>
											<button class="btn btn-main d-xl-none" @click="toggleFilterMobile()" v-if="category === 1">ดูสินค้า {{ totalResult }} รายการ</button>
											<button class="btn btn-main d-xl-none" @click="toggleFilterMobile()" v-if="category === 2">ดูร้านค้า {{ totalResult }} ร้าน</button>
										</div>
									</div>
								</div>
							</div>
						</template>
					</div>

					<div class="content-body">
						<div class="content-mobile-filter">
							<button @click="toggleFilterMobile()"><b-icon icon="sliders" class="filter-icon"></b-icon> กรองผลการค้นหา</button>
						</div>

						<div class="list">
							<gadget-sort-bar v-show="category === 1" :sortOption="sortTireOption" :defaultOption="sortTireOption[0].id" @changeSort="changeTireSort($event)" class="mb-3"></gadget-sort-bar>
							<gadget-sort-bar v-show="category === 2" :sortOption="sortStoreOption" :defaultOption="sortStoreOption[0].id" @changeSort="changeStoreSort($event)" class="mb-3"></gadget-sort-bar>

							<div class="loading" v-if="!isResultReady">
								<div class="item">
									<loader-content :isDisplay="true"></loader-content>
								</div>
							</div>

							<template v-if="isResultReady">
								<gadget-search-tag :tags="tags" @removeTag="removeTag($event)" class="mb-3" v-if="tags && tags.length > 0"></gadget-search-tag>
								<product-grid 
									v-if="category === 1"
									:products="resultTireList"
									@openStoreList="openStoreList($event)"
									grid="column-5"
									trackingReferrer="search">
								</product-grid>
								<store-grid v-if="category === 2" :stores="resultStoreList"></store-grid>
								<paging
									@page="changePage($event)"
									:activePage="activePage"
									:totalPage="totalPage"
									class="mt-4 mb-2">
								</paging>
							</template>
						</div>
					</div>
				</div>
			</template>

			<template v-if="category === 3">
				<div class="loading" v-if="!isResultReady">
					<div class="item">
						<loader-content :isDisplay="true"></loader-content>
					</div>
				</div>
				<template v-if="isResultReady">
					<blog-content
						:contents="resultArticleList"
						:landing="routerPath.ARTICLE"
						v-if="isResultReady">
					</blog-content>
					<paging
						@page="changePage($event)"
						:activePage="activePage"
						:totalPage="totalPage"
						class="mt-4 mb-2">
					</paging>
				</template>
			</template>
		</div>
	</div>
</template>

<script>
import $ from 'jquery';
import RouterPath from '@/router/path';
import Helper from '@/services/helper';
import OptionProductSort from '@/services/staticOption/productSort';
import ProductService from '@/services/productService';
import StoreService from '@/services/storeService';
import ArticleService from '@/services/articleService';
import FilterSearch from '@/components/gadget/FilterSearch';
import ProductGrid from '@/components/product/ProductGridList';
import StoreGrid from '@/components/store/StoreGridList';
import BlogContent from '@/components/blog/BlogContent';
import GadgetSortBar from '@/components/gadget/SortBar';
import GadgetSearchTag from '@/components/gadget/SearchTag';

export default {
	components: {
		FilterSearch,
		ProductGrid,
		StoreGrid,
		BlogContent,
		GadgetSortBar,
		GadgetSearchTag
	},
	data() {
		return {
			routerPath: RouterPath,
			category: Helper.getGlobalSearchTab(),
			isContentReady: false,
			isResultReady: false,
			isShowMobileFilter: false,
			tags: null,
			title: Helper.getQueryString('k'),
			pageTitle: 'ค้นหา "' + Helper.getQueryString('k') + '"',
			filterOptions: [],
			resultTireList: [],
			resultStoreList: [],
			resultArticleList: [],
			totalResult: 0,
			activePage: 1,
			totalPage: 0,
			filter: null,
			sortTireOption: OptionProductSort.option,
			sortStoreOption: [
                { id: 'near', name: 'ร้านที่ใกล้' },
                { id: 'popular', name: 'ยอดนิยม' }
            ],
			sortType: 'popular'
		};
	},
	mounted() {
        this.getContentSearch();
    },
    methods: {
		changeCategory(category) {
			if (this.category !== category) {
				this.category = category;
				this.activePage = 1;
				this.$router.push(RouterPath.SEARCH + '?k=' + Helper.getQueryString('k') + '&t=' + this.category);
			}
		},
		async getContentSearch() {
            switch (this.category) {
				case 1:
					this.sortType = 'near';
					this.getTireSearchContent();
					break;

				case 2:
					this.sortType = 'near';
					this.getStoreSearchContent();
					break;

				case 3:
					this.getArticleList();
					break;
			}
        },
		async getSearchResult() {
            switch (this.category) {
				case 1:
					this.getTireResult();
					break;

				case 2:
					this.getStoreResult();
					break;

				case 3:
					this.getArticleList();
					break;
			}
        },
        async getTireSearchContent() {
            this.isContentReady = false;
			this.isResultReady = false;
			
			const param = this.setupParamContent();
            const result = await ProductService.getContentSearch(param);

			if (result) {
				this.filterOptions = result.data.filter;

				this.getTireResult();
			}

			this.isContentReady = true;
        },
		async getStoreSearchContent() {
            this.isContentReady = false;
			this.isResultReady = false;
			this.activePage = 1;
			
            const result = await StoreService.getLandingContent();

			if (result.data) {
				this.filterOptions = result.data.filter;

				this.getStoreResult();
			}

			this.isContentReady = true;
        },
		async getTireResult() {
			this.isResultReady = false;

			const param = this.setupParamTire();
            const result = await ProductService.searchProduct(param);

			if (result) {
				this.resultTireList = result.data.resultList;
				this.tags = result.data.tags;
				this.totalResult = result.data.totalResult;
				this.totalPage = result.data.totalPage;
				this.isResultReady = true;	
			}
		},
		async getStoreResult() {
            this.isResultReady = false;

			const param = await this.setupParamStore();
            const result = await StoreService.searchStore(param);

			this.resultStoreList = result.data.resultList;
			this.tags = this.adjustTags(result.data.tags);
			this.totalResult = result.data.totalResult;
			this.totalPage = result.data.totalPage;
            this.isResultReady = true;
        },
		async getArticleList() {
            this.isResultReady = false;

			const param = this.setupParamArticle();
            const result = await ArticleService.getArticleList(param);

			this.resultArticleList = result.data.resultList;
			this.totalPage = result.data.totalPage;
            this.isResultReady = true;
        },
		setupParamContent() {
			const param = {
				keyword: Helper.getQueryString('k')
			};

			return param;
		},
		setupParamTire() {
			const generalParam = {
				page: this.activePage,
				filter: this.filter,
				sort: this.sortType
			};
			const urlParam = this.setupParamContent();
			const param = { ...generalParam, ...urlParam };

			return param;
		},
		async setupParamStore() {
			let pos = null;

			if (navigator.geolocation) {
				pos = await this.getCoordinates();
			}

			const param = {
				page: this.activePage,
				keyword: Helper.getQueryString('k'),
				latitude: pos ? pos.lat : null,
				longtitude: pos ? pos.long : null,
				filter: this.filter,
				sort: this.sortType
			};

			return param;
		},
		setupParamArticle() {
			const generalParam = {
				page: this.activePage
			};
			const urlParam = this.setupParamContent();
			const param = { ...generalParam, ...urlParam };

			return param;
		},
		async getCoordinates() {
			try {
				const pos = await new Promise((resolve, reject) => {
					navigator.geolocation.getCurrentPosition(resolve, reject);
				});
			
				return {
					lat: pos.coords.latitude,
					long: pos.coords.longitude
				};
			} catch {
				return null;
			}
		},
		adjustTags(data) {
			const tagText = data.filter(x => !x.text.match(/<img/));
			const tagImg = data
				.filter(x => x.text.match(/<img/))
				.map(x => ({...x, text: $(x.text).attr('title')}));

			return [...tagText, ...tagImg];
		},
		changeFilter(data) {
			this.filter = data.filter;
			this.changePage(1);
		},
		changePage(page) {
			this.activePage = page;
			this.getSearchResult();
		},
		changeTireSort(data) {
			this.sortType = data.value;
			this.activePage = 1;

			if (this.sortType) {
				this.getTireResult();
			}
		},
		changeStoreSort(data) {
			this.sortType = data.value;
			this.activePage = 1;

			if (this.sortType) {
				this.getStoreResult();
			}
		},
		toggleFilterMobile() {
			this.isShowMobileFilter = !this.isShowMobileFilter;
			this.$root.$emit('rootSetContentScrollable', this.isShowMobileFilter);
		},
		resetFilter() {
			this.$refs.filterSearch.resetFilter();
		},
		removeTag(data) {
			const tagIndex = this.tags.findIndex(x => x.value === data.item.value);
			this.tags.splice(tagIndex, 1);
			this.$refs.filterSearch.updateFilterSelection(this.tags);
		}
    },
	metaInfo() {
		const metaDescription = 'เช็คราคายางรถยนต์ ทุกยี่ห้อ ทุกรุ่น ที่ขายในเมืองไทย พร้อมข้อมูลยางและโปรโมชั่นอัพเดตล่าสุด | เปลี่ยนยางรถยนต์ เช็ค ชอป ชัวร์ YELLOWTiRE';

        return {
            title: this.pageTitle + ' | YELLOWTiRE',
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: metaDescription },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: this.pageTitle },
                { vmid: 'og-desc', property: 'og:description', content: metaDescription },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: location.origin + Helper.metaDefault().logo },
				{ vmid: 'twitter-description', property: 'twitter:description', content: metaDescription },
                { vmid: 'twitter-title', property: 'twitter:title', content: this.pageTitle },
                { vmid: 'twitter-image', property: 'twitter:image', content: location.origin + Helper.metaDefault().logo }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.search {
	padding: 15px 0;

	@media only screen and (max-width: $screenMedium) {
		padding-top: 0;
	}

	h1 {
		display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding-right: 130px;

        @media only screen and (max-width: $screenSmall) {
            padding-right: 0;
        }

        label {
            font-size: 14px;
            padding: 3px 20px;
            font-family: 'Open Sans', 'Kanit';
            margin: 0;
            color: #555;
            background: $mainColor;
            font-weight: bold;
            text-align: center;
            margin-right: 15px;
            @include borderRadius(1000px);
        }

        span {
            display: block;
            font-size: 14px;
            font-weight: bold;
            color: #000;
            margin-top: 3px;

            @media only screen and (max-width: $screenSmall) {
                font-size: 13px;
            }

            .icon {
                margin-right: 5px;
                opacity: 0.7;
            }
        }
	}

	.category {
		border-bottom: 1px solid #ccc;

		button {
			display: inline-block;
			background: 0 0;
			border: none;
			border-bottom: 2px;
			outline: none;
			font-size: 16px;
			color: #333;
			padding: 5px 0 10px;
			margin: 0 30px -1px 0;

			@media only screen and (max-width: $screenExtraSmall) {
				font-size: 15px;
				margin: 0 15px -1px 0;
			}

			&.active {
				font-weight: bold;
				border-bottom: 3px solid #ff4b4b;
			}

			.icon {
				margin-right: 3px;
			}
		}
	}

	.list {
		@media only screen and (max-width: $screenExtraLarge) {
			padding-top: 20px;
		}

		.loading {
			@include gridContentWrapper(0 -5px);

			.item {
				display: block;
				width: 100%;
				height: 250px;
			}
		}
	}
}
</style>